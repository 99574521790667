<template>
  <div class="doctor-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>Kết quả khám bệnh</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col
            v-if="getUserRole().name !== 'medical_facility'"
            md="4"
          >
            <select-facility
              v-model="medicalFacility"
              :label="null"
            />
          </b-col>
          <b-col :md="getUserRole().name !== 'medical_facility' ? 4 : 6">
            <select-doctor
              v-model="doctor"
              :medical-facility="medicalFacility"
            />
          </b-col>
          <b-col :md="getUserRole().name !== 'medical_facility' ? 4 : 6">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm ..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.maxResultCount,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-apartment-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>
                <span v-else-if="props.column.field === 'doctor'">
                  <b-media
                    v-if="props.row.doctor"
                    vertical-align="center"
                  >
                    <template #aside>
                      <b-avatar
                        rounded
                        :src="`https://api.vtelehealth.vn:8443/media/view?token=${encodeURIComponent(props.row.doctor.avatar)}-100.webp`"
                        size="48"
                      />
                    </template>
                    <b-link
                      class="font-weight-bold d-block text-nowrap text-secondary"
                    >
                      {{ props.row.doctor.full_name }}
                    </b-link>
                    <small class="text-muted">{{ props.row.doctor.email }}</small>
                  </b-media>
                </span>
                <span v-else-if="props.column.field === 'patient'">
                  <b-media
                    v-if="props.row.customer"
                    vertical-align="center"
                  >
                    <template #aside>
                      <b-avatar
                        rounded
                        :src="`https://api.vtelehealth.vn:8443/media/view?token=${encodeURIComponent(props.row.customer.avatar)}-100.webp`"
                        size="48"
                      />
                    </template>
                    <b-link
                      class="font-weight-bold d-block text-nowrap text-secondary"
                    >
                      {{ props.row.customer.full_name }}
                    </b-link>
                    <small class="text-muted">{{ props.row.medical_examination_reason }}</small>
                  </b-media>
                </span>
                <span v-else-if="props.column.field === 'item'">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        :src="`https://api.vtelehealth.vn:8443/media/view?token=${encodeURIComponent(props.row.doctor.avatar)}-100.webp`"
                        size="48"
                      />
                    </template>
                    <b-link
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ props.row.doctor.full_name }}
                    </b-link>
                    <small class="text-muted">{{ props.row.doctor.phone }}</small>
                  </b-media>
                </span>

                <span v-else-if="props.column.field === 'number_meetings'">
                  {{ props.row.meetings.length }}
                </span>

                <span v-else-if="props.column.field === 'created_at'">
                  {{ parseDate(props.row.created_at) }}
                </span>

                <span v-else-if="props.column.field === 'drugs'">
                  <ul
                    v-for="(drug, index) in props.row.drugs"
                    :key="index"
                    class="list-inline"
                  >
                    <li>
                      <span>{{ drug.name }} ({{ drug.quantity }} {{ parseDrugUnit(drug.unit) }})</span>
                      <small class="text-muted"><br> {{ drug.instruction }}</small>
                    </li>
                  </ul>
                </span>

                <span v-else-if="props.column.field === 'status'">
                  <b-avatar
                    :id="`row-${props.row.id}`"
                    size="32"
                    :variant="resolveStatusVariant(props.row.status).variant"
                  >
                    <feather-icon
                      :icon="resolveStatusVariant(props.row.status).icon"
                    />
                  </b-avatar>
                  <b-tooltip
                    :target="`row-${props.row.id}`"
                    placement="top"
                  >
                    <p class="mb-0">
                      {{ resolveStatusVariant(props.row.status).title }}
                    </p>
                  </b-tooltip>
                </span>
                <span v-else-if="props.column.field === 'diagnoses'">
                  <ul
                    v-for="(diagnose, index) in props.row.diagnoses"
                    :key="index"
                    class="list-inline"
                  >
                    <li>
                      <span>{{ diagnose.name }} {{ diagnose.conclution ? (" - " + diagnose.conclution) : '' }}</span>
                    </li>
                  </ul>
                </span>
                <span v-else-if="props.column.field === 'check'">
                  <b-avatar
                    :id="`family-doctor-${props.row.id}`"
                    size="32"
                    :variant="resolveFamilyDoctorVariant(props.row.is_family_doctor).variant"
                  >
                    <feather-icon
                      :icon="resolveFamilyDoctorVariant(props.row.is_family_doctor).icon"
                    />
                  </b-avatar>
                  <b-tooltip
                    :target="`family-doctor-${props.row.id}`"
                    placement="top"
                  >
                    <p class="mb-0">
                      {{ resolveFamilyDoctorVariant(props.row.is_family_doctor).title }}
                    </p>
                  </b-tooltip>
                </span>
                <span v-else-if="props.column.field === 'note'">
                  <span v-if="props.row.note">{{ props.row.note }}</span>
                  <small
                    v-if="props.row.reminder > 0"
                    class="font-italic"
                  ><br v-if="props.row.note">(Hẹn tái khám sau {{ props.row.reminder }} ngày)</small>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="căn hộ"
                    modal="modal-apartment"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.maxResultCount"
                      :options="['10', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.maxResultCount"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BAvatar,
  BTooltip,
  BMedia,
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
// eslint-disable-next-line import/no-cycle
import moment from 'moment';
import { drugUnits } from "@/utils/constant";
import SelectDoctor from '@/views/components/SelectDoctor.vue';
import SelectFacility from "@/views/components/SelectFacility.vue";
import { getUserRole } from '@/auth/utils';
import usePrescriptionList from './usePrescriptionList';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BAvatar,
    BTooltip,
    BFormSelect,
    BSpinner,
    BMedia,
    TableActions,
    VueGoodTable,
    SelectDoctor,
    SelectFacility,
  },

  setup() {
    const {
      analytics,
      item,
      columns,
      rows,
      department,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      doctor,
      medicalFacility,
      fetchData,

      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveFamilyDoctorVariant,
    } = usePrescriptionList();

    return {
      analytics,
      item,
      columns,
      rows,
      department,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      doctor,
      medicalFacility,

      fetchData,

      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveFamilyDoctorVariant,
      getUserRole,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    parseDate(dateString) {
      return moment(dateString).format("DD-MM-YYYY HH:mm");
    },
    parseDrugUnit(unit) {
      const filter = drugUnits().filter(_obj => _obj.value === unit);
      if (filter.length > 0) {
        return filter[0].title;
      }
      return unit;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.doctor-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 180px !important;
            }
            &:nth-child(6) {
               min-width: 300px !important;
            }
          }
        }
      }
    }
}
</style>
